import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from "../../blocks/alert/src/AlertBlock";
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from "../../framework/src/SingletonFactory";

import HomeScreenAdapter from "../../blocks/adapters/src/HomeScreenAdapter";
import InfoPageAdapter from "../../blocks/adapters/src/InfoPageAdapter";
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";

//Assembler generated adapters start
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
// Customizable Area End

const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter();

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure.",
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S {}

interface SS {}

class HomeScreen extends BlockComponent<Props, S, SS> {
  static instance: HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to AutoBxEngStoreRest07093268!
              </Text>
            </View>
            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={"InfoPage"}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={"Alert"}
              onPress={() => this.showAlert("Example", "This happened")}
            />
<CustomTextItem content={'ccavenue-payment'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'studio-store-restaurant-core'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'studio-store-restaurant-components'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'studio-store-restaurant-router'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'studio-store-restaurant-services'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'studio-store-restaurant-theme'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'studio-store-restaurant-translations'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Search'}  onPress={() => navigation.navigate("Search")} />
<CustomTextItem content={'studio-store-restaurant-utilities'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'EmailAccountRegistration'}  onPress={() => navigation.navigate("EmailAccountRegistration")} />
<CustomTextItem content={'utilities'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'ForgotPassword'}  onPress={() => navigation.navigate("ForgotPassword")} />
<CustomTextItem content={'OTPInputAuth'}  onPress={() => navigation.navigate("OTPInputAuth")} />
<CustomTextItem content={'EmailAccountLoginBlock'}  onPress={() => navigation.navigate("EmailAccountLoginBlock")} />
<CustomTextItem content={'user-profile-basic'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Ordermanagement'}  onPress={() => navigation.navigate("Ordermanagement")} />
<CustomTextItem content={'filteritems'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Dashboard'}  onPress={() => navigation.navigate("Dashboard")} />
<CustomTextItem content={'ContactUs'}  onPress={() => navigation.navigate("ContactUs")} />
<CustomTextItem content={'addressmanagement'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Settings'}  onPress={() => navigation.navigate("Settings")} />
<CustomTextItem content={'OrderDetailView'}  onPress={() => navigation.navigate("OrderDetailView")} />
<CustomTextItem content={'Notifications'}  onPress={() => navigation.navigate("Notifications")} />
<CustomTextItem content={'ShoppingCart'}  onPress={() => navigation.navigate("ShoppingCart")} />
<CustomTextItem content={'OrderSummary'}  onPress={() => navigation.navigate("OrderSummary")} />
<CustomTextItem content={'Pushnotifications2'}  onPress={() => navigation.navigate("Pushnotifications2")} />
<CustomTextItem content={'core'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Contentapproval2'}  onPress={() => navigation.navigate("Contentapproval2")} />
<CustomTextItem content={'Rolesandpermissions2'}  onPress={() => navigation.navigate("Rolesandpermissions2")} />
<CustomTextItem content={'Adminconsole2'}  onPress={() => navigation.navigate("Adminconsole2")} />
<CustomTextItem content={'Loyaltysystem2'}  onPress={() => navigation.navigate("Loyaltysystem2")} />
<CustomTextItem content={'Shippingoptions'}  onPress={() => navigation.navigate("Shippingoptions")} />
<CustomTextItem content={'Wishlist2'}  onPress={() => navigation.navigate("Wishlist2")} />
<CustomTextItem content={'Forgotpassword3'}  onPress={() => navigation.navigate("Forgotpassword3")} />
<CustomTextItem content={'Emailnotifications2'}  onPress={() => navigation.navigate("Emailnotifications2")} />
<CustomTextItem content={'Timetracker2'}  onPress={() => navigation.navigate("Timetracker2")} />
<CustomTextItem content={'Categoriessubcategories2'}  onPress={() => navigation.navigate("Categoriessubcategories2")} />
<CustomTextItem content={'Customform4'}  onPress={() => navigation.navigate("Customform4")} />
<CustomTextItem content={'Discountsoffers2'}  onPress={() => navigation.navigate("Discountsoffers2")} />
<CustomTextItem content={'Apiintegration52'}  onPress={() => navigation.navigate("Apiintegration52")} />

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? "100vh" : "auto",
    backgroundColor: "#F5FCFF",
  },
  container: {
    flex: 1,
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white",
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: "bold",
    fontSize: 16,

    padding: 10,
  },
  button: {
    backgroundColor: "#6200EE",
    padding: 15,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  header: {
    backgroundColor: "#6200EE",
    padding: 15,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  item: {
    backgroundColor: "#00000000",
    padding: 18,
    color: "#6200EE",
    fontSize: 16,
    fontWeight: "normal",
  },
});
// Customizable Area End
export default HomeScreen;